<template>
  <v-container fluid>
    <v-progress-linear v-if="carregandoBuscar" indeterminate />
    <v-row>
      <v-col cols="12">
        <validation-observer ref="formDados">
          <v-form ref="form" class="mt-4">
            <v-row>
              <v-col cols="12" md="6">
                <validation-provider v-slot="{ errors }" vid="nome">
                  <v-text-field
                    v-model="dados.nome"
                    outlined
                    dense
                    :disabled="carregandoBuscar"
                    hide-details="auto"
                    label="Nome"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
              <v-col cols="12" md="6">
                <validation-provider v-slot="{ errors }" vid="apelido">
                  <v-text-field
                    v-model="dados.apelido"
                    outlined
                    dense
                    :disabled="carregandoBuscar"
                    hide-details="auto"
                    label="Apelido"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="4">
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{}">
                    <validation-provider
                      v-slot="{ errors }"
                      vid="data_nascimento"
                    >
                      <v-text-field
                        v-model="dataNascimentoFormatada"
                        v-mask="'##/##/####'"
                        label="Data de nascimento"
                        :error-messages="errors"
                        dense
                        hide-details="auto"
                        :disabled="carregandoBuscar"
                        outlined
                        append-icon="mdi-calendar"
                        @blur="
                          dados.data_nascimento = formatarDataApi(
                            dataNascimentoFormatada
                          )
                        "
                        @click:append="menu = true"
                      />
                    </validation-provider>
                  </template>
                  <v-date-picker
                    v-model="dados.data_nascimento"
                    no-title
                    class="ma-0"
                    @input="menu = false"
                  />
                </v-menu>
              </v-col>

              <v-col cols="12" md="3">
                <validation-provider v-slot="{ errors }" vid="tipo_pessoa">
                  <v-radio-group
                    v-model="dados.tipo_pessoa"
                    row
                    hide-details
                    :error-messages="errors"
                    :disabled="carregandoBuscar"
                    class="mt-1"
                  >
                    <v-radio label="Física" value="1" hide-details="auto" />
                    <v-radio label="Jurídica" value="2" hide-details="auto" />
                  </v-radio-group>
                </validation-provider>
              </v-col>

              <v-col cols="12" md="4">
                <validation-provider
                  v-if="dados.tipo_pessoa === '1'"
                  v-slot="{ errors }"
                  vid="cpf"
                >
                  <v-text-field
                    v-model="dados.cpf"
                    v-mask="'###.###.###-##'"
                    outlined
                    dense
                    :disabled="carregandoBuscar"
                    hide-details="auto"
                    label="CPF"
                    :error-messages="errors"
                  />
                </validation-provider>

                <validation-provider
                  v-if="dados.tipo_pessoa === '2'"
                  v-slot="{ errors }"
                  vid="cnpj"
                >
                  <v-text-field
                    v-model="dados.cnpj"
                    v-mask="'##.###.###/####-##'"
                    outlined
                    :disabled="carregandoBuscar"
                    dense
                    hide-details="auto"
                    label="CNPJ"
                    :error-messages="errors"
                  />
                </validation-provider>
              </v-col>
            </v-row>
          </v-form>
        </validation-observer>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-right">
        <btn-salvar outlined :carregando="carregando" @click="salvar" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import pessoasApi from "@/api/pessoas";
import utilsData from "@/utils/formatar-data-input";

export default {
  props: {
    pessoaId: {
      type: Number,
      default: 0,
    },
    limparAoSalvar: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      dataNascimentoFormatada: "",
      validacaoFormulario: {},
      menu: false,
      carregando: false,
      carregandoBuscar: false,
      dados: {
        tipo_pessoa: "1",
        data_nascimento: "",
      },
    };
  },

  watch: {
    "dados.data_nascimento"() {
      this.dataNascimentoFormatada = utilsData.ptBr(this.dados.data_nascimento);
    },

    pessoaId: {
      immediate: true,
      handler(id) {
        if (id) {
          this.buscar();
        }
      },
    },
  },

  methods: {
    async buscar() {
      try {
        this.dados = {};
        this.carregandoBuscar = true;
        const resposta = await pessoasApi.buscar(this.pessoaId);
        this.dados = resposta.data;
      } catch (e) {
        console.error(e);
      } finally {
        this.carregandoBuscar = false;
      }
    },

    async salvar() {
      try {
        this.carregando = true;

        let mensagem;
        let resposta;

        if (this.dados.id) {
          mensagem = this.$mensagens._("sucesso_editar");
          resposta = await pessoasApi.atualizar(this.dados.id, this.dados);
        } else {
          mensagem = this.$mensagens._("sucesso_adicionar");
          resposta = await pessoasApi.inserir(this.dados);
        }

        if (this.limparAoSalvar) {
          this.$refs.form.reset();
        }

        this.$snackbar.mostrar({ cor: "success", mensagem });
        return this.$emit("salvar", resposta.data);
      } catch (e) {
        if (e.response.status === 422) {
          this.$refs.formDados.setErrors(this.$erroApi.validacao(e));
          return;
        }

        this.$snackbar.mostrar({ cor: "error", mensagem: this.$erroApi._(e) });
      } finally {
        this.carregando = false;
      }
    },

    formatarDataApi(data) {
      return utilsData.api(data);
    },
  },
};
</script>
